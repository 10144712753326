<template>
  <div v-if="isSwiperLoaded" class="restaurant-card-slider relative">
    <component
      :is="swiperComponent.main.value"
      :navigation="{
        nextEl: `.${swiperNext}`,
        prevEl: `.${swiperPrev}`,
      }"
      :pagination="paginationOption"
      :space-between="props.spaceBetween || 10"
      :modules="[swiperModule.navigation.value, swiperModule.pagination.value]"
      :slides-per-view="slidePerView"
      @slide-change="onSlideChange"
    >
      <component
        :is="swiperComponent.slide.value"
        v-for="(restaurant, index) in restaurants"
        :key="`${swiperId}-${createLoopId({
          name: restaurant.name,
          id: restaurant.id,
        })}-${index}`"
        class="restaurant-card-slide"
      >
        <RestaurantCard
          v-bind="restaurant"
          :is-loading="isLoading"
          class=""
          @on-click="(data) => onCardClicked(data, index + 1)"
        />
      </component>
      <div
        v-if="!isLoading"
        class="swiper-pagination custom-swiper-active-pagination-trending"
      ></div>
    </component>

    <div class="arrow-position">
      <div
        class="home-slider-prev swiper-button-prev slider-prev"
        :class="swiperPrev"
      >
        <IconLeftArrow class="w-5 text-black-primary" />
      </div>
      <div
        class="swiper-button-next home-slider-next slider-next"
        :class="swiperNext"
      >
        <IconRightArrow class="w-5 text-black-primary" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, toRefs, onMounted } from "vue";
import { nanoid } from "nanoid";
import IconRightArrow from "~icons/hh-icons/icon-right-arrow";
import IconLeftArrow from "~icons/hh-icons/icon-left-arrow";
import { isMobile, isTablet } from "~/helpers/screenSize";
import RestaurantCard from "~/components/restaurant/RestaurantCard/RestaurantCard.vue";
import type {
  RestaurantCardProps,
  RestaurantCardSliderProps,
} from "~/types/Restaurant";
import useSwiper from "~/composables/useSwiper";
import { createLoopId } from "~/helpers/restaurant";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "~/assets/css/swiper.scss";

const props = withDefaults(defineProps<RestaurantCardSliderProps>(), {
  showArrow: true,
  showPagination: true,
  spaceBetween: 10,
});
const emits = defineEmits<{
  (
    e: "on-card-clicked",
    data: { card: RestaurantCardProps; position: number | string }
  ): void;
}>();
const { restaurants, slidePerView } = toRefs(props);
const swiperId = nanoid(5);
const swiperPrev = `swiper-prev-${swiperId}`;
const swiperNext = `swiper-next-${swiperId}`;
const isReachEnd = ref(false);
const isReachStart = ref(true);

const pagination = {
  clickable: true,
  dynamicBullets: true,
  dynamicMainBullets: 1,
  el: ".swiper-pagination",
};
const paginationOption =
  props.sideImage && isMobile && !isTablet ? pagination : false;
const onSlideChange = (val: any) => {
  isReachStart.value = val.isBeginning;
  isReachEnd.value = val.isEnd;
};
const { swiperComponent, swiperModule, loadSwiper, isSwiperLoaded } =
  useSwiper();

function onCardClicked(cardObj: RestaurantCardProps, position: number) {
  emits("on-card-clicked", { card: cardObj, position });
}

onMounted(async () => {
  await loadSwiper();
});
</script>
<script lang="ts">
export default {
  name: "RestaurantCardSliderMain",
};
</script>

<style lang="scss">
.restaurant-card-slider {
  .restaurant-card-slide {
    width: 160px;
    height: auto;
    @screen lg {
      width: 204px;
    }
    @apply pb-0;
  }

  .restaurant-card-slide:first-child {
  }

  .base-restaurant-card {
    width: 160px;
    height: 276px;

    @screen lg {
      width: 100%;
      height: 372px;
    }
  }
  .arrow-position {
    position: absolute;
    top: -34px;
    right: 4px;
    @apply hidden lg:block;
  }

  .swiper-button-next {
    top: 40%;
  }

  .swiper-button-prev {
    top: 40%;
    left: -100px;
  }
  .swiper-button-next svg,
  .swiper-button-prev svg {
    width: 50%;
    height: 50%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @apply h-10 w-10 rounded-full border bg-white;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 19px;
  }
  .swiper-pagination.custom-swiper-active-pagination-trending {
    &.swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 19px;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
      background: red !important;
    }
    .swiper-pagination-bullet {
      @apply h-[6px] w-[6px] scale-100 transform;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply h-[6px] w-[20px];
      border-radius: 50px;
    }
  }
}

.restaurant-card-slider.is-loading {
  .restaurant-card-slide-wrapper {
    @apply flex items-center justify-center;
    @screen lg {
      @apply flex-wrap;
    }
  }
  .restaurant-card-slide {
    @apply pr-2;
  }
}
</style>
